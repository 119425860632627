<template>
    <div class="archive">
        <div class="button-group clearfix" v-if="!mediaGroupData.archived">
            <div class="button-group-left">
                <router-link :to="{ name: 'UserMediaGroupMediaList' }" :title="text.view_active_images" class="btn btn-frameless">{{text.view_active_images}}</router-link>
            </div>
            <div class="button-group-right">
                <a href="#" :title="text.select_all" @click="selectAll($event)" @keyup.enter="selectAll($event)" class="btn btn-secondary btn-smyspace" v-if="(mediaGroupArchiveMediaData.length > 0 && (selectedMediaItems.length !== mediaGroupArchiveMediaData.length))">{{text.select_all}}</a>
                <a href="#" :title="text.deselect_all" @click="deselectAll($event)" @keyup.enter="deselectAll($event)" class="btn btn-secondary btn-smyspace" v-if="(mediaGroupArchiveMediaData.length > 0 && (selectedMediaItems.length === mediaGroupArchiveMediaData.length))">{{text.deselect_all}}</a>
                <a href="#" :title="text.restore_selected" class="btn btn-tertiary" :class="{'btn-inactive': selectedMediaItems.length < 1}" @click="restoreSelected($event)" @keyup.enter="restoreSelected($event)">{{text.restore_selected}}</a>
            </div>
        </div>
        <div class="panel panel-body">
            <ul class="image-list" v-if="mediaGroupArchiveMediaData.length > 0">
                <li class="image-list-item" v-for="row in mediaGroupArchiveMediaData" :key="row.id">
                    <span class="image-list-item-inner">
                        <router-link :to="{ name: 'UserMediaGroupMediaView', params: { 'mediaItemID' : row.id } }" :title="text.view + ' ' + row.name" class="image image-border"><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></router-link>
                        <p class="image-title">{{row.name}}</p>
                        <span class="image-meta">
                            <ViewAction :routeName="'UserMediaGroupMediaView'" :routeParams="{ 'mediaItemID' : row.id }" :linkName="row.name" />
                            <span class="form-input" v-if="!mediaGroupData.archived">
                                <label :for="'selectimage' + row.id" class="form-checkbox">
                                    <input type="checkbox" name="selectimage" :id="'selectimage' + row.id" :value="row.id" v-model="selectedMediaItems" /><span></span>
                                </label>
                            </span>
                        </span>
                    </span>
                </li>
            </ul>
            <p v-else>{{text.no_results}}</p>
        </div>
        <Pagination v-if="mediaGroupArchiveMediaPagination.totalPages > 0" :pagination="mediaGroupArchiveMediaPagination" @go-previous="refreshMediaItemList" @go-next="refreshMediaItemList" />
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {MEDIA_ROOT} from "@/shared/consts";
    import {mapActions, mapState} from "vuex";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    import Pagination from "@/components/pagination";
    import ViewAction from "@/components/actionlinks/view";

    export default {
        name: 'MediaGroupMediaArchive',
        components: {Pagination, ViewAction},
        mixins: [formatContentMixin, mediaGroupActionsMixin],
        data() {
            return {
                mediaGroupData: [],
                mediaGroupArchiveMediaData: [],
                mediaGroupArchiveMediaPagination: [],
                selectedMediaItems: [],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            mediaGroupID() {
                return this.$route.params.mediaGroupID;
            },
            text() {
                return lang;
            },
            pageTitlePrefix() {
                return this.mediaGroupData.archived ? "(" + this.text.archived + ") " : "";
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction", "setBreadCrumbsAction"]),
            async refreshMediaItemList(page = 1){
                this.selectedMediaItems  = [];
                await this.loadMediaGroupArchiveMediaByID(this.mediaGroupID, page);
            },
            async getMediaGroupData(){
                this.mediaGroupData = await this.loadMediaGroupByID(this.mediaGroupID);

                // update page title
                this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.pageTitlePrefix + this.mediaGroupData.name) });

                // update breadcrumbs
                this.setBreadCrumbsAction({
                    route : this.$route.matched,
                    replace : [{
                        search : "%mediaGroupName%",
                        replace : this.mediaGroupData.name
                    }]
                });
            },
            restoreSelected(){
                this.restoreMediaItems(this.selectedMediaItems, 'refreshMediaItemList');
            }
        },
        async created() {
            this.getMediaGroupData();
        },
        async mounted() {
            this.refreshMediaItemList(1);
        }
    }
</script>