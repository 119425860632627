xx<template>
    <div>
        <div class="columns">
            <div class="content">
                <Form v-model="imageUploadForm" ref="form" />

                <a href="#" :title="text.upload" class="btn btn-primary" :class="[{'btn-inactive' : isUploading}]" @click="upload($event)" @keyup.enter="upload($event)">{{text.upload}}</a> <router-link :to="{ name: 'UserMediaGroupMedia', params : { mediaGroupID : this.mediaGroupID } }" :title="text.finish" class="btn btn-tertiary">{{text.finish}}</router-link>

            </div>
        </div>
        <div v-if="uploadedImages.length > 0">
            <h2>Uploaded images</h2>
            <ul class="image-list">
                <li class="image-list-item" v-for="row in uploadedImages" :key="row.id">
                    <span class="image-list-item-inner">
                        <span class="image image-border"><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></span>
                        <p class="image-title">{{row.name}}</p>
                        <span class="image-meta">
                            <EditAction :routeName="'UserMediaGroupMediaEdit'" :routeParams="{ 'mediaItemID' : row.id }" :linkName="row.name" />
                        </span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {log} from "@/shared/utils";
    import {mapActions, mapState} from "vuex";
    import {MESSAGE_TYPE_ERROR, MEDIA_ROOT} from "@/shared/consts";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {formWrapperMixin} from "@/views/mixins/formwrapper";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    import Form from "@/components/form/form";
    import EditAction from "@/components/actionlinks/edit";
    import {uploadToMediaGroupByID} from "../../../shared/dataservice";
    //import {MESSAGE_TYPE_ERROR} from "@/shared/consts";

    export default {
        name: 'MediaGroupMediaNew',
        components: {Form, EditAction},
        mixins: [formatContentMixin, formWrapperMixin, mediaGroupActionsMixin],
        data() {
            return {
                isUploading : false,
                mediaGroupData: [],
                imageUploadForm: [
                    {
                        id: 1,
                        type: 'text',
                        name: 'name',
                        label: 'Image name',
                        placeholder: "",
                        tooltip: "",
                        value : "",
                        validate : {
                            type: 'string',
                            required: true,
                            min : 5,
                            max : 205
                        }
                    },
                    {
                        id: 2,
                        type: 'file',
                        name: 'image',
                        label: 'Image file',
                        placeholder: "",
                        tooltip: "",
                        value: [],
                        validate: {
                            type: 'file',
                            required: true
                        }
                    }
                ],
                uploadedImages: [],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            mediaGroupID() {
                return this.$route.params.mediaGroupID;
            },
            text() {
                return lang;
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            },
        },
        methods: {
            ...mapActions(["setBreadCrumbsAction"]),
            async getMediaGroupData(){
                this.mediaGroupData = await this.loadMediaGroupByID(this.mediaGroupID);

                // update breadcrumbs
                this.setBreadCrumbsAction({
                    route : this.$route.matched,
                    replace : [{
                        search : "%mediaGroupName%",
                        replace : this.mediaGroupData.name
                    }]
                });
            },
            async upload(e){
                e.preventDefault();

                log(["Save triggered by wrapper"]);

                if(this.$refs.form.save()){
                    this.isUploading = true;

                    let myFileUpload = new FormData();
                    myFileUpload.append('name', this.getFormDataValueByName(this.imageUploadForm, "name"));
                    myFileUpload.append('image', this.getFormDataValueByName(this.imageUploadForm, "image"));

                    log(["Image upload data", myFileUpload]);

                    let result = await uploadToMediaGroupByID(this.mediaGroupID, myFileUpload);

                    if(result.status === 201){
                        log(["Image uploaded ok"]);
                        this.imageUploaded(result.data.data);
                    }
                    else
                    {
                        log(["Form save result no ok"]);
                        this.$store.dispatch('addSystemMessageAction', {
                            'type' : MESSAGE_TYPE_ERROR,
                            'message' : this.text.message_media_item_upload_error
                        });
                    }
                    this.isUploading = false;
                }
                else
                {
                    // TO DO SHOW ERROR
                    log(["Form is NOT ok to be saved"]);
                }
            },
            imageUploaded (imageData) {
                log(["Image uploaded", imageData]);
                this.uploadedImages.push(imageData);

                this.resetUploadForm();
            },
            resetUploadForm (){
                log("Reset Form");

                this.$refs.form.clear();

                this.setFormDataValueByName(this.imageUploadForm, "name", "");
                this.setFormDataValueByName(this.imageUploadForm, "image", []);
            }
        },
        async created() {
            this.getMediaGroupData();
        },
    }
</script>