<template>
    <div :class="{ archive : mediaGroupData.archived}">
        <div class="button-group clearfix" v-if="!mediaGroupData.archived">
            <div class="button-group-left">
                <router-link :to="{ name: 'UserMediaGroupMediaNew' }" :title="text.upload_new_image" class="btn btn-smyspace">{{text.upload_new_image}}</router-link><router-link :to="{ name: 'UserMediaGroupMediaArchive' }" :title="text.view_archived_images" class="btn btn-frameless">{{text.view_archived_images}}</router-link>
            </div>
            <div class="button-group-right">
                <a href="#" :title="text.select_all" @click="selectAll($event)" @keyup.enter="selectAll($event)" class="btn btn-secondary btn-smyspace" v-if="(mediaGroupMediaData.length > 0 && (selectedMediaItems.length !== mediaGroupMediaData.length))">{{text.select_all}}</a>
                <a href="#" :title="text.deselect_all" @click="deselectAll($event)" @keyup.enter="deselectAll($event)" class="btn btn-secondary btn-smyspace" v-if="(mediaGroupMediaData.length > 0 && (selectedMediaItems.length === mediaGroupMediaData.length))">{{text.deselect_all}}</a>
                <a href="#" :title="text.archive_selected" class="btn btn-tertiary" :class="{'btn-inactive': selectedMediaItems.length < 1}" @click="archiveSelected($event)" @keyup.enter="archiveSelected($event)">{{text.archive_selected}}</a>
            </div>
        </div>
        <div class="panel panel-body">
            <ul class="image-list" v-if="mediaGroupMediaData.length > 0">
                <li class="image-list-item" v-for="row in mediaGroupMediaData" :key="row.id">
                    <span class="image-list-item-inner">
                        <router-link :to="{ name: 'UserMediaGroupMediaView', params: { 'mediaItemID' : row.id } }" :title="text.view + ' ' + row.name" class="image image-border"><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></router-link>
                        <p class="image-title">{{row.name}}</p>
                        <span class="image-meta">
                            <ViewAction :routeName="'UserMediaGroupMediaView'" :routeParams="{ 'mediaItemID' : row.id }" :linkName="row.name" />
                            <EditAction :routeName="'UserMediaGroupMediaEdit'" :routeParams="{ 'mediaItemID' : row.id }" :linkName="row.name" />
                            <span class="form-input" v-if="!mediaGroupData.archived">
                                <label :for="'selectimage' + row.id" class="form-checkbox">
                                    <input type="checkbox" name="selectimage" :id="'selectimage' + row.id" :value="row.id" v-model="selectedMediaItems" /><span></span>
                                </label>
                            </span>
                        </span>
                    </span>
                </li>
            </ul>
            <p v-else>{{text.no_results}}</p>
        </div>
        <Pagination v-if="mediaGroupMediaPagination.totalPages > 0" :pagination="mediaGroupMediaPagination" @go-previous="refreshMediaItemList" @go-next="refreshMediaItemList" />
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {log} from "@/shared/utils";
    import {MEDIA_ROOT} from "@/shared/consts";
    import {mapActions, mapState} from "vuex";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    import Pagination from "@/components/pagination";
    import ViewAction from "@/components/actionlinks/view";
    import EditAction from "@/components/actionlinks/edit";

    export default {
        name: 'MediaGroupMediaList',
        components: {Pagination, ViewAction, EditAction},
        mixins: [formatContentMixin, mediaGroupActionsMixin],
        data() {
            return {
                mediaGroupData: [],
                mediaGroupMediaData: [],
                mediaGroupMediaPagination: [],
                selectedMediaItems: [],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            mediaGroupID() {
                return this.$route.params.mediaGroupID;
            },
            text() {
                return lang;
            },
            pageTitlePrefix() {
                return this.mediaGroupData.archived ? "(" + this.text.archived + ") " : "";
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction", "setBreadCrumbsAction"]),
            async refreshMediaItemList(page = 1){
                this.selectedMediaItems = [];
                await this.loadMediaGroupMediaByID(this.mediaGroupID, page);
            },
            async getMediaGroupData(){
                this.mediaGroupData = await this.loadMediaGroupByID(this.mediaGroupID);

                // update page title
                this.updatePageTitle();
//              // update breadcrumbs
                this.updateBreadCrumbs();
            },
            updatePageTitle() {
                log(["What info do I have here?", this.$route]);

                // update page title
                this.setPageTitleAction({
                    title : this.$route.meta.pageTitle.replace("%s%", this.mediaGroupData.name) + this.pageTitlePrefix,
                    isEditable: true,
                    editableTitle : this.mediaGroupData.name,
                    changeSaved : false
                });
            },
            updateBreadCrumbs() {
                // update breadcrumbs
                this.setBreadCrumbsAction({
                    route : this.$route.matched,
                    replace : [{
                        search : "%mediaGroupName%",
                        replace : this.mediaGroupData.name
                    }]
                });
            },
            async saveNewMediaGroupName() {
                let result = await this.saveMediaGroup(this.mediaGroupData.id, this.pageTitle.editableTitle);

                if(result.status === 200){
                    this.getMediaGroupData();
                }
                else
                {
                    log(["Save result no ok"]);
                }
            },
            archiveSelected(){
                this.archiveMediaItems(this.selectedMediaItems, 'refreshMediaItemList');
            }
        },
        async created() {
            this.getMediaGroupData();
        },
        async mounted() {
            this.refreshMediaItemList(1);
        },
        watch: {
            'pageTitle.changeSaved' (){
                log(["SAVED Watched page title change", this.pageTitle.editableTitle, this.mediaGroupData.name, this.pageTitle.changeSaved]);

                if(this.pageTitle.changeSaved && (this.pageTitle.editableTitle !== this.mediaGroupData.name)){
                    log(["Title change has been saved and is different from original"]);
                    this.saveNewMediaGroupName();
                }
            },
        },
    }
</script>